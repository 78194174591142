import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import { Link } from 'gatsby'
import DiscountServiceBanner from '../images/discount.svg'
import UnlimitedServiceBanner from '../images/unlimited.svg'
import DedicatedServiceBanner from '../images/dedicated.svg'
import TechnologyHireServiceBanner from '../images/technology_hire.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'


const HireDedicatedDevelopers = () => {
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/hire-dedicated-developers" />
          <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="Hire Dedicated Developers" description='eWise - Hire Dedicated Developers'/>
  <section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24 ">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative"><strong className="text-50 md:text-55 lg:text-6xl xl:text-70 2xl:text-8xl block">Hire Dedicated</strong> <span className="relative top-m-10 md:top-m-10 lg:top-m-15 2xl:top-m-37 inline-block md:pl-0 xl:pl-0 2xl:pl-0 text-gray text-2xl md:text-3xl lg:text-34 xl:text-34 2xl:text-50">Resources</span> <span className="hidden xl:inline-block xl:left-m-32 2xl:left-m-40 xl:top-m15 2xl:top-m32 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-74 2xl:h-74"></span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4">
                        <div className="inner relative m-auto flex flex-wrap justify-center  w-full sm:w-auto">
                            <div className="pt-10 sm:pt-20 w-full sm:w-auto  flex sm:inline-block flex-wrap">
                                <div className="box px-4 sm:px-6 pb-20 w-1/2 sm:w-auto">
                                    <div className="inner sm:w-150 h-150 2xl:w-180 2xl:h-180 bg-white rounded-3xl flex justify-center items-center flex-col flex-wrap text-center pt-12 p-2 sm:pt-4 sm:p-4 shadow-hireusbox relative">
                                        <div className="p-3 absolute bg-lightblue w-70 h-70 rounded-full flex-wrap flex justify-center items-center text-white top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold text-lg">
                                            <img className="" src={DiscountServiceBanner} alt="Fixed Monthly Price"/>
                                        </div>
                                        
                                        <h6 className=" text-sm 2xl:text-base font-normal">Fixed Monthly Price</h6>
                                    </div>
                                </div>
                                <div className="box px-4 sm:px-6 pb-20 sm:pb-0 w-1/2 sm:w-auto">
                                    <div className="inner sm:w-150 h-150 2xl:w-180 2xl:h-180 bg-white rounded-3xl flex justify-center items-center flex-col flex-wrap text-center pt-12 p-2 sm:pt-4 sm:p-4 shadow-hireusbox relative">
                                        <div className="p-3 absolute bg-lightblue w-70 h-70 rounded-full flex-wrap flex justify-center items-center text-white top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold text-lg">
                                        <img className="" src={UnlimitedServiceBanner} alt="Dedicated Resource/Team"/>
                                        </div>
                                        <h6 className=" text-sm 2xl:text-base font-normal">Dedicated Resource/Team</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full sm:w-auto flex sm:inline-block flex-wrap">
                                <div className="box px-4 sm:px-6  pb-20 w-1/2 sm:w-auto">
                                    <div className="inner sm:w-150 h-150 2xl:w-180 2xl:h-180 bg-white rounded-3xl flex justify-center items-center flex-col flex-wrap text-center pt-12 p-2 sm:pt-4 sm:p-4 shadow-hireusbox relative">
                                        <div className="p-3 absolute bg-lightblue w-70 h-70 rounded-full flex-wrap flex justify-center items-center text-white top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold text-lg">
                                        <img className="" src={DedicatedServiceBanner} alt="Unlimited Projects Tasks"/>
                                        </div>
                                        <h6 className=" text-sm 2xl:text-base font-normal">Unlimited Projects/Tasks</h6>
                                    </div>
                                </div>
                                <div className="box px-4 sm:px-6 w-1/2 sm:w-auto">
                                    <div className="inner sm:w-150 h-150 2xl:w-180 2xl:h-180 bg-white rounded-3xl flex justify-center items-center flex-col flex-wrap text-center pt-12 p-2 sm:pt-4 sm:p-4 shadow-hireusbox relative">
                                        <div className="p-3 absolute bg-lightblue w-70 h-70 rounded-full flex-wrap flex justify-center items-center text-white top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold text-lg">
                                        <img className="" src={TechnologyHireServiceBanner} alt="Multi Technology Stack Expertise"/>
                                        </div>
                                        <h6 className=" text-sm 2xl:text-base font-normal">Multi Technology Stack Expertise</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="About Shape"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="About Shape"/>
    </section>
  <section className="relative pb-16 sm:pb-20 lg:pb-28">
      <div className="container">
          <div className="-mx-4">
            <div className="text_content px-4">
                <h4 className="text-26 md:text-3xl lg:text-32 xl:text-35 2xl:text-40 text-center max-w-90 m-auto"><strong>Choose the Best plan to Scale and
                    Grow Your Agency Business</strong></h4>
            </div>
            <div className="flex flex-wrap bg-white shadow-footer max-w-full xl:max-w-90 p-5 lg:pr-0 rounded-3xl w-100 m-auto mt-10">
                <div className="w-full lg:w-30 bg-lightblue py-10 lg:py-0 px-8 rounded-3xl flex justify-center items-start flex-wrap flex-col">
                    
                    <h4 className=" text-22 md:text-26 lg:text-3xl  font-bold  text-center lg:text-left mb-5">Choose the Best plan to Grow Your Business</h4>
                    <div className="btn-group footer-btn  m-auto lg:m-0">
                        <Link className="btn bg-pink" to="/start-your-next-project" title="Contact Us">Contact Us</Link>
                    </div>
                </div>
                <div className="w-full lg:w-70p px-0 lg:px-4 flex flex-wrap justify-center">
                    <div className="w-full hours_plan bg-white rounded-3xl px-7 py-7 pt-16 mx-0 md:mx-4 max-w-full md:max-w-290 xl:max-w-300 2xl:max-w-320 shadow-hireusbox mt-20 lg:mt-12 relative">
                        <h6 className="border-lightblue border-5 absolute bg-pink min-w-90 min-h-90 2xl:min-w-100 2xl:min-h-100 rounded-full flex-wrap flex justify-center items-center text-white top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold text-lg">Hourly</h6>
                        <ul className="checkmark">
                            <li className="text-black relative pl-8  text-sm 2xl:text-base mb-2">Android App Development</li>
                            <li className="text-black relative pl-8  text-sm 2xl:text-base mb-2">iOS App Development</li>
                            <li className="text-black relative pl-8  text-sm 2xl:text-base mb-2">Flutter App Development</li>
                            <li className="text-black relative pl-8  text-sm 2xl:text-base mb-2">Codeigniter Web Development</li>
                            <li className="text-black relative pl-8  text-sm 2xl:text-base mb-2">PHP Web Development</li>
                            <li className="text-black relative pl-8  text-sm 2xl:text-base mb-2">Graphics Design</li>
                            <li className="text-black relative pl-8  text-sm 2xl:text-base mb-2">Laravel Web Development</li>
                            <li className="text-black relative pl-8  text-sm 2xl:text-base mb-2">Magento Web Development</li>
                            <li className="text-black relative pl-8  text-sm 2xl:text-base">Shopify Web Development</li>
                        </ul>
                    </div>
                    <div className="w-full month_plan bg-white rounded-3xl px-7 py-7 pt-16 mx-0 md:mx-4 max-w-full md:max-w-290 xl:max-w-300 2xl:max-w-320 shadow-hireusbox mt-20 lg:mt-12 relative">
                        <h6 className="border-lightblue border-5 absolute bg-pink min-w-90 min-h-90 2xl:min-w-100 2xl:min-h-100 rounded-full flex-wrap flex justify-center items-center text-white top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold text-lg">Monthly</h6>
                        <ul className="checkmark">
                            <li className="text-black relative pl-8 text-sm 2xl:text-base mb-2">Android App Development</li>
                            <li className="text-black relative pl-8 text-sm 2xl:text-base mb-2">iOS App Development</li>
                            <li className="text-black relative pl-8 text-sm 2xl:text-base mb-2">Flutter App Development</li>
                            <li className="text-black relative pl-8 text-sm 2xl:text-base mb-2">Codeigniter Web Development</li>
                            <li className="text-black relative pl-8 text-sm 2xl:text-base mb-2">PHP Web Development</li>
                            <li className="text-black relative pl-8 text-sm 2xl:text-base mb-2">Graphics Design</li>
                            <li className="text-black relative pl-8 text-sm 2xl:text-base mb-2">Laravel Web Development</li>
                            <li className="text-black relative pl-8 text-sm 2xl:text-base mb-2">Magento Web Development</li>
                            <li className="text-black relative pl-8 text-sm 2xl:text-base">Shopify Web Development</li>
                        </ul>
                    </div>
                </div>
            </div>

          </div>
      </div>
  </section>
    </Layout>
  )
}

export default HireDedicatedDevelopers